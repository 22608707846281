import React, { useState, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import HotelOutlinedIcon from '@mui/icons-material/HotelOutlined';
import BathtubOutlinedIcon from '@mui/icons-material/BathtubOutlined';
import SquareFootOutlinedIcon from '@mui/icons-material/SquareFootOutlined';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import Tooltip from '@mui/material/Tooltip';
import CalendarModal from './calendar';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import PocketBaseContext from '../../../PocketBaseContext';

const ListingCard = ({ property }) => {
    const [isFavorited, setIsFavorited] = useState(false);
    const [imageSrc, setImageSrc] = useState('');
    const [isCalendarModalOpen, setIsCalendarModalOpen] = useState(false);
    const pb = useContext(PocketBaseContext);
    const user = pb?.authStore?.model;
    const [showError, setShowError] = useState(false);
    const [errorOpacity, setErrorOpacity] = useState(1);
    const [selectedHome, setSelectedHome] = useState(null);
    const [showShareOptions, setShowShareOptions] = useState(false);

    // console.log(property)
    const imageUrl = property.Image_1; // Assuming this is the URL to fetch the image


    useEffect(() => {
        const fetchImage = async () => {
            try {
                const response = await fetch(imageUrl, {
                    method: 'GET',
                    headers: {
                        'Referer': 'https://www.trulia.com/' // Replace with the correct referrer
                    }
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                const blob = await response.blob();
                const imageObjectURL = URL.createObjectURL(blob);
                setImageSrc(imageObjectURL);
                console.log(imageSrc)
            } catch (error) {
                console.error('Error fetching image:', error);
                // If an error occurs, do not set the image source to a fallback image
                // This will ensure that properties without images are not shown
            }
        };

        if (imageUrl) { // Check if imageUrl is not undefined or null
            fetchImage();
        }
    }, [imageUrl]);

    // useEffect(() => {
    //     const checkIfLiked = async () => {
    //         if (!user) return; // Early return if user is not available

    //         try {
    //             const response = await pb.collection('Liked_Homes').getList(1, 1, {
    //                 filter: `user_id="${user.id}" && home_id="${property.zpid}"`,
    //             });
    //             setIsFavorited(response.totalItems > 0);
    //         } catch (error) {
    //             console.error('Error checking if home is liked:', error);
    //         }
    //     };
    //     checkIfLiked();
    // }, [user, property.zpid, pb]);

    const handleShareClick = () => {
        setShowShareOptions(!showShareOptions);
    };

    const handleFavoriteClick = async (e) => {
        e.stopPropagation();
        e.preventDefault();

        if (!user) {
            alert('Please log in to like homes');
            return;
        }

        try {
            if (isFavorited) {
                const record = await pb.collection('Liked_Homes').getFirstListItem(`user_id="${user.id}" && home_id="${property.zpid}"`);
                await pb.collection('Liked_Homes').delete(record.id);
                setIsFavorited(false);
                console.log('Home removed from favorites successfully');
            } else {
                await pb.collection('Liked_Homes').create({
                    user_id: user.id,
                    home_id: property.zpid,
                });
                setIsFavorited(true);
                console.log('Home saved to favorites successfully');
            }
        } catch (error) {
            console.error('Error toggling favorite status:', error);
        }
    }

    const openCalendarModal = () => {
        if (!user) {
            alert('Please Log In');
        } else {
            setSelectedHome(property.zpid);
            setIsCalendarModalOpen(true);
        }
    };

    const closeCalendarModal = () => setIsCalendarModalOpen(false);

    return (
        <Link to={`/listing/${property.id}`}>
            <div className="relative overflow-hidden rounded-lg mb-2 h-74 border border-gray-300 transition-shadow duration-300 hover:shadow-xl">
                <img
                    src={imageSrc}
                    alt={property.Address_Full}
                    className="w-full h-40 object-cover rounded-t-lg transition-transform duration-300 hover:scale-105"
                    onError={(e) => { e.target.onerror = null; e.target.src = 'https://via.placeholder.com/150'; }}
                />
                <div className="p-3 pb-16"> {/* Increase bottom padding here */}
                    <h5 className="font-bold truncate">{property.Address_Full || 'Address not available'}</h5>
                    <div className="flex justify-center">
                        <p className="text-lg font-semibold bg-clip-text text-transparent bg-gradient-to-r from-[#EC6227] via-[#E80458] to-[#5900B3]">
                            {property.Price ? `$${property.Price.toLocaleString()}` : 'Price not available'}
                        </p>
                    </div>
                    <div className="flex justify-around mt-2 text-gray-600">
                        <div className="flex items-center space-x-2 hover:text-black hover:border-b-2 hover:border-black">
                            <HotelOutlinedIcon fontSize="medium" />
                            <p className="text-md font-semibold">{property.Beds || 'N/A'}</p>
                        </div>
                        <div className="flex items-center space-x-2 hover:text-black hover:border-b-2 hover:border-black">
                            <BathtubOutlinedIcon fontSize="medium" />
                            <p className="text-md font-semibold">{property.Bath || 'N/A'}</p>
                        </div>
                        <div className="flex items-center space-x-2 hover:text-black hover:border-b-2 hover:border-black">
                            <SquareFootOutlinedIcon fontSize="medium" />
                            <p className="text-md font-semibold">{property.Sqr_Ft ? property.Sqr_Ft.toLocaleString() : 'N/A'}</p>
                        </div>
                    </div>

                </div>

                {/* Action Buttons */}
                <div className="absolute bottom-0 left-0 right-0 p-2 flex justify-between bg-white bg-opacity-90 transition-opacity duration-300 hover:opacity-100 opacity-70">
                    <Tooltip title={isFavorited ? "Remove from Favorites" : "Add to Favorites"}>
                        <span onClick={handleFavoriteClick} className="cursor-pointer">
                            {isFavorited ? (
                                <FavoriteIcon style={{ color: 'red' }} className='cursor-pointer ' />
                            ) : (
                                <FavoriteBorderOutlinedIcon />
                            )}
                        </span>
                    </Tooltip>

                    <Tooltip title="Share">
                        <span className="cursor-pointer">
                            <ShareIcon />
                        </span>
                    </Tooltip>

                    <Tooltip title="Add to Calendar">
                        <button onClick={openCalendarModal} className="p-2 rounded-full ">
                            <CalendarMonthOutlinedIcon />
                        </button>
                    </Tooltip>
                </div>
                {showError && (
                    <div className={`bg-red-100 text-red-800 border border-red-500 p-4 text-center transition-opacity duration-500`} style={{ opacity: errorOpacity }}>
                        <h1 className="text-lg font-bold">Please log in to access this feature.</h1>
                    </div>
                )}
                <CalendarModal isOpen={isCalendarModalOpen} onClose={closeCalendarModal} />
            </div>
        </Link>
    );
};

export default ListingCard;





