import React, { useState } from 'react';

export default function PropertyStatusDropdown({ onSelectStatus }) {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedStatus, setSelectedStatus] = useState('ForSale'); // Default selection

    // Mapping of status values to display labels
    const statusLabels = {
        ForSale: 'For Sale',
        ForRent: 'For Rent',
        RecentlySold: 'Recently Sold'
    };

    const handleStatusClick = (status) => {
        setSelectedStatus(status);
        setIsOpen(false);
        if (onSelectStatus) onSelectStatus(status); // Pass the selected status (raw value) to parent if needed
    };

    return (
        <div className="relative inline-block text-left mr-2">
            <div className="gradient-border-container cursor-pointer">
                {/* Dropdown Button */}
                <button
                    onClick={() => setIsOpen(!isOpen)}
                    className="text-xl font-medium text-gray-500 hover:text-transparent bg-clip-text hover:bg-gradient-to-r from-[#EC6227] via-[#E80458] to-[#5900B3] transition-all duration-300 px-4 py-2 rounded-md inline-flex items-center"
                >
                    {statusLabels[selectedStatus]} {/* Display formatted label */}
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className={`ml-2 h-5 w-5 transform transition-transform duration-300 ${isOpen ? 'rotate-180' : 'rotate-0'}`}
                        viewBox="0 0 20 20"
                        fill="currentColor"
                    >
                        <path
                            fillRule="evenodd"
                            d="M5.23 7.21a.75.75 0 011.06.02L10 10.97l3.71-3.74a.75.75 0 111.06 1.06l-4.24 4.25a.75.75 0 01-1.06 0L5.23 8.27a.75.75 0 01.02-1.06z"
                            clipRule="evenodd"
                        />
                    </svg>
                </button>
            </div>

            {/* Dropdown Menu */}
            {isOpen && (
                <div className="absolute mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-10">
                    <div className="py-1">
                        <button
                            className="w-full text-left px-4 py-2 text-gray-700 hover:bg-gray-200"
                            onClick={() => handleStatusClick('ForSale')}
                        >
                            For Sale
                        </button>
                        <button
                            className="w-full text-left px-4 py-2 text-gray-700 hover:bg-gray-200"
                            onClick={() => handleStatusClick('ForRent')}
                        >
                            For Rent
                        </button>
                        <button
                            className="w-full text-left px-4 py-2 text-gray-700 hover:bg-gray-200"
                            onClick={() => handleStatusClick('RecentlySold')}
                        >
                            Recently Sold
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
}
