import React, { useState, useRef, useEffect } from 'react';
import GradientButton from '../Reusables/gradient_button';
import ContactForm from './contactform';
import { motion } from 'framer-motion';

const MobileLandingPage = () => {
    const [showContact, setShowContact] = useState(false);
    const scrollableRef = useRef(null);
    const [scrollY, setScrollY] = useState(0);

    const handleContact = () => {
        setShowContact(!showContact);
    };

    useEffect(() => {
        const handleScroll = () => {
            if (scrollableRef.current) {
                const currentScrollY = scrollableRef.current.scrollTop;
                // console.log("Scroll event triggered. ScrollY:", currentScrollY);  // Log scroll position

                setScrollY(currentScrollY);
                // setShowArrow(currentScrollY <= 100); // Adjust as needed
            }
        };

        const scrollableElement = scrollableRef.current;
        scrollableElement.addEventListener('scroll', handleScroll);

        return () => {
            if (scrollableElement) {
                scrollableElement.removeEventListener('scroll', handleScroll);
            }
        };
    }, []);

    return (
        <div className="flex flex-col h-screen overflow-y-scroll bg-white">
            {/* Top Section */}
            <div className="bg-white shadow-md rounded-lg flex flex-col items-center text-black p-4 sticky top-0 z-10">
                <img
                    src="/logo.png"
                    alt="Realti Logo"
                    className="w-24 mb-2 transform hover:scale-105 transition-transform duration-300"
                />
                <h1 className="text-3xl font-extrabold tracking-wide text-center mb-2">
                    Realti
                </h1>
                <p className="text-xl text-center bg-gradient-to-r from-[#EC6227] via-[#E80458] to-[#5900B3] text-transparent bg-clip-text font-bold">
                    Safe. Easy. Transparent.
                </p>
            </div>

            {/* Dynamic Section */}
            <div className="w-full text-center text-sm mt-4 flex flex-col items-center">
                <div className="relative flex flex-col items-center justify-center w-full">
                    <div className="text-2xl bg-gradient-to-r from-[#EC6227] via-[#E80458] to-[#5900B3] text-transparent bg-clip-text font-semibold mb-4">
                        Built for:
                    </div>
                    <div className="text-lg mb-4 text-black">
                        {scrollY < 400 ? 'Homeowners' : scrollY >= 400 && scrollY < 800 ? 'Agents' : 'Everyone'}
                    </div>
                </div>
                {/* <img
                    src="/landing_page/Final.svg"
                    alt="Realti House"
                    className="w-48 mt-4 rounded-lg"
                /> */}
            </div>

            {/* Scrollable Content */}
            <div ref={scrollableRef} className="w-full overflow-y-auto">
                {/* About Section */}
                <motion.section className="flex flex-col items-center p-6 bg-white">
                    <div className="text-center">
                        <h2 className="text-4xl font-bold mb-4 text-gradient bg-clip-text text-transparent bg-gradient-to-r from-[#EC6227] via-[#E80458] to-[#5900B3]">
                            Realti
                        </h2>
                        <p className="text-base text-gray-700">
                            Realti is an all-in-one platform for every step of your real estate transaction.
                        </p>
                    </div>
                    <img src="/landing_page/Town 1.png" alt="Realti Visual" className="w-48 mt-4" />
                </motion.section>

                {/* Features Section */}
                <motion.section className="flex flex-col items-center p-6 bg-white">
                    <h2 className="text-3xl font-bold mb-4 text-gradient bg-clip-text text-transparent bg-gradient-to-r from-[#EC6227] via-[#E80458] to-[#5900B3]">
                        Schedule with Ease
                    </h2>
                    <p className="text-base text-gray-700 text-center">
                        No more back and forth with realtors for showings, inspections, and walk-throughs. With Realti, scheduling is a breeze.
                    </p>
                    <img src="/landing_page/Schedule.svg" alt="Scheduling" className="w-48 mt-4" />
                </motion.section>

                {/* Services Section */}
                <motion.section className="flex flex-col items-center p-6 bg-white">
                    <h2 className="text-3xl font-bold mb-4 text-gradient bg-clip-text text-transparent bg-gradient-to-r from-[#EC6227] via-[#E80458] to-[#5900B3]">
                        Send Money Safely
                    </h2>
                    <p className="text-base text-gray-700 text-center">
                        Monetary transfers are simple, easy, and secure with Realti. See how and where your money is at all times.
                    </p>
                    <img src="/landing_page/Money.svg" alt="Money Transfer" className="w-48 mt-4" />
                </motion.section>

                {/* Contact Section */}
                <motion.section className="flex flex-col items-center p-6 bg-white">
                    <h2 className="text-3xl font-bold mb-4 text-gradient bg-clip-text text-transparent bg-gradient-to-r from-[#EC6227] via-[#E80458] to-[#5900B3]">
                        Contact Us
                    </h2>
                    <p className="text-base text-gray-700 text-center mb-4">
                        Join our waitlist or contact us with any questions!
                    </p>
                    {!showContact && (
                        <GradientButton
                            className="bg-gradient-to-r from-[#EC6227] via-[#E80458] to-[#5900B3] text-white font-bold py-3 px-6 rounded-full"
                            onClick={handleContact}
                        >
                            Contact Us
                        </GradientButton>
                    )}
                    {showContact && (
                        <motion.div initial={{ opacity: 0, y: 50 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 1.5, ease: "easeInOut" }}>
                            <ContactForm />
                        </motion.div>
                    )}
                </motion.section>
            </div>
        </div>
    );
};

export default MobileLandingPage;
