import React from 'react';
import useWindowSize from './windowstate';
import MobileLandingPage from './mobile_landingpage';
import DesktopLandingPage from './desktop_landingpage';

const LandingPage = () => {
    const { width } = useWindowSize();
    const isMobile = width < 768;

    return (
        <div>
            {isMobile ? <MobileLandingPage /> : <DesktopLandingPage />}
        </div>
    );
};

export default LandingPage;
