import React, { useState, useEffect, useContext } from "react";
import LogoutButton from "../../Registration/logout";
import PocketBaseContext from '../../../PocketBaseContext';
import EmojiEmotionsOutlinedIcon from '@mui/icons-material/EmojiEmotionsOutlined';
import GradientButton from "../../Reusables/gradient_button";


export default function ProfileComponent() {
    const pb = useContext(PocketBaseContext);
    const user = pb.authStore.model;
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [username, setUsername] = useState("");
    const [isEditing, setIsEditing] = useState(false);

    useEffect(() => {
        if (user) {
            setName(user.name || "");
            setEmail(user.email || "");
            setUsername(user.username || "");
        }
    }, [user]);

    console.log(user)

    const handleSave = async () => {
        try {
            const data = {
                name: name,
                email: email,
                username: username,
            };

            // Update the user in the database
            const updatedUser = await pb.collection('users').update(user.id, data);

            // Update the authStore with the new data
            pb.authStore.model.name = updatedUser.name;
            pb.authStore.model.email = updatedUser.email;
            pb.authStore.model.username = updatedUser.username;

            alert("Profile updated successfully!");
            setIsEditing(false);
        } catch (error) {
            console.error("Error updating profile:", error);
            alert("Failed to update profile. Please try again.");
        }
    };

    const handleEdit = () => {
        setIsEditing(true);
    };

    const handleNameChange = (e) => {
        console.log("Name changed to:", e.target.value); // Debugging log
        setName(e.target.value);
    };

    const handleEmailChange = (e) => {
        console.log("Email changed to:", e.target.value); // Debugging log
        setEmail(e.target.value);
    };

    const handleUsernameChange = (e) => {
        console.log("Username changed to:", e.target.value); // Debugging log
        setUsername(e.target.value);
    };

    const handleCancel = () => {
        setName(user.name || "");
        setEmail(user.email || "");
        setUsername(user.username || "");
        setIsEditing(false);
    };

    return (
        <div className="w-full max-w-md mx-auto border rounded-md shadow-md">
            <div className="bg-gray-100 p-6">
                <div className="flex items-center gap-4">
                    <div className="h-16 w-16 rounded-full bg-gray-300 flex items-center justify-center overflow-hidden">
                        {user.avatar ? (
                            <img
                                src={`https://realti.pockethost.io/api/files/_pb_users_auth_/${user.id}/${user.avatar}`}
                                alt={name}
                                className="h-full w-full object-cover"
                            />
                        ) : (
                            <EmojiEmotionsOutlinedIcon className="h-full w-full text-gray-600" />
                        )}
                    </div>
                    <div className="grid gap-1">
                        {isEditing ? (
                            <>
                                <input
                                    type="text"
                                    value={name}
                                    onChange={handleNameChange}
                                    className="text-2xl font-bold border px-2 py-1 rounded focus:outline-none bg-white"
                                />
                                <input
                                    type="text"
                                    value={username}
                                    onChange={handleUsernameChange}
                                    className="text-gray-500 border px-2 py-1 rounded focus:outline-none bg-white"
                                />
                            </>
                        ) : (
                            <>
                                <span className="text-2xl font-bold">{name}</span>
                                <span className="text-gray-500">{username}</span>
                            </>
                        )}
                    </div>
                </div>
            </div>
            <div className="p-6 grid gap-4">
                <div className="grid gap-2">
                    <div className="grid grid-cols-[100px_1fr] items-center text-sm text-gray-500">
                        <span>Email:</span>
                        {isEditing ? (
                            <input
                                type="email"
                                value={email}
                                onChange={handleEmailChange}
                                className="w-full border px-2 py-1 rounded"
                            />
                        ) : (
                            <span>{email}</span>
                        )}
                    </div>
                    <div className="grid grid-cols-[100px_1fr] items-center text-sm text-gray-500">
                        <span>Joined:</span>
                        <span>{new Date(user.created).toLocaleDateString()}</span>
                    </div>
                </div>
                <hr className="my-4 border-gray-300" />
                {isEditing ? (
                    <>
                        <GradientButton onClick={handleSave}>
                            Save Changes
                        </GradientButton>
                        <GradientButton onClick={handleCancel}>
                            Cancel
                        </GradientButton>
                    </>
                ) : (
                    <GradientButton onClick={handleEdit}>
                        Edit Profile
                    </GradientButton>
                )}
                <LogoutButton />
            </div>
        </div>
    );
}
