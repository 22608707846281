import React, { useState } from 'react';
import { Line } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
} from 'chart.js';

// Register the required components for Chart.js
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const TaxAndPriceHistoryGraph = ({ taxHistory, priceHistory }) => {
    const [activeGraph, setActiveGraph] = useState('price'); // State to track which graph to show

    // Format Tax History Data with validation and handling for missing data
    const formattedTaxData = taxHistory
        ? taxHistory
            .filter(entry => entry.time && entry.taxPaid) // Ensure time and taxPaid exist
            .map(entry => ({
                time: new Date(entry.time).toLocaleDateString(),  // Format time to readable date
                taxPaid: Number(entry.taxPaid) || 0  // Ensure taxPaid is a number
            }))
            .sort((a, b) => new Date(a.time) - new Date(b.time))
        : [];

    // Format Price History Data with validation and handling for missing data
    const formattedPriceData = priceHistory
        ? priceHistory
            .filter(entry => entry.time && entry.price) // Ensure time and price exist
            .map(entry => ({
                time: new Date(entry.time).toLocaleDateString(),  // Format time to readable date
                value: Number(entry.price) || 0  // Ensure price is a number
            }))
            .sort((a, b) => new Date(a.time) - new Date(b.time))
        : [];


    const priceData = {
        labels: formattedPriceData.map(entry => entry.time),  // Ensure labels are strings (dates)
        datasets: [
            {
                label: 'Property Value ($)',
                data: formattedPriceData.map(entry => entry.value),  // Ensure data is a number array
                borderColor: '#EC6227',
                backgroundColor: '#EC6227',
                borderWidth: 2
            }
        ]
    };

    const taxData = {
        labels: formattedTaxData.map(entry => entry.time),  // Ensure labels are strings (dates)
        datasets: [
            {
                label: 'Tax Paid ($)',
                data: formattedTaxData.map(entry => entry.taxPaid),  // Ensure data is a number array
                borderColor: '#5900B3',
                backgroundColor: '#5900B3',
                borderWidth: 2
            }
        ]
    };


    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top'
            },
            title: {
                display: false
            }
        },
        scales: {
            y: {
                beginAtZero: true,
                ticks: {
                    callback: function (value) {
                        return `$${value.toLocaleString()}`;
                    }
                }
            }
        }
    };

    return (
        <div className="bg-white p-4 rounded-lg mt-4">
            {/* Toggle Buttons */}
            <div className="flex space-x-4 mb-4">
                <button
                    className={`text-xl font-bold ${activeGraph === 'price' ? 'bg-clip-text text-transparent bg-gradient-to-r from-[#EC6227] via-[#E80458] to-[#5900B3]' : 'text-gray-500'}`}
                    onClick={() => setActiveGraph('price')}
                >
                    Price History
                </button>
                <button
                    className={`text-xl font-bold ${activeGraph === 'tax' ? 'bg-clip-text text-transparent bg-gradient-to-r from-[#EC6227] via-[#E80458] to-[#5900B3]' : 'text-gray-500'}`}
                    onClick={() => setActiveGraph('tax')}
                >
                    Tax History
                </button>

            </div>

            {/* Conditional Rendering for the Graphs */}
            {activeGraph === 'price' ? (

                <Line data={priceData} options={options} />
            ) : (
                <Line data={taxData} options={options} />
            )}
        </div>
    );
};

export default TaxAndPriceHistoryGraph;
