import React, { useState, useEffect, useContext } from 'react';
import { Calendar, momentLocalizer, Views } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import GradientButton from '../../Reusables/gradient_button';
import './CustomCalendar.css'; // Import your custom CSS
import PocketBaseContext from '../../../PocketBaseContext';

const localizer = momentLocalizer(moment);

const CalendarModal = ({ isOpen, onClose, homeId }) => {
    const [events, setEvents] = useState([]);
    const pb = useContext(PocketBaseContext);
    const [view, setView] = useState(Views.WEEK);

    // Custom Toolbar
    const CustomToolbar = ({ onNavigate, label }) => {
        return (
            <div className="rbc-toolbar">
                <div className="rbc-btn-group">
                    <GradientButton onClick={() => onNavigate('PREV')}>Previous</GradientButton>
                    <GradientButton onClick={() => onNavigate('TODAY')}>Today</GradientButton>
                    <GradientButton onClick={() => onNavigate('NEXT')}>Next</GradientButton>
                </div>
                <span className="rbc-toolbar-label">{label}</span>
                <div className="rbc-btn-group">
                    <GradientButton onClick={() => setView(Views.MONTH)}>Month</GradientButton>
                    <GradientButton onClick={() => setView(Views.WEEK)}>Week</GradientButton>
                    <GradientButton onClick={() => setView(Views.DAY)}>Day</GradientButton>
                </div>
            </div>
        );
    };

    useEffect(() => {
        if (isOpen && homeId) {
            const fetchEvents = async () => {
                try {
                    const records = await pb.collection('Events').getFullList({
                        filter: `Home_Id = "${homeId}"`,
                        sort: 'Start',
                    });
                    const formattedEvents = records.map(event => ({
                        start: new Date(event.Start),
                        end: new Date(event.End),
                        title: event.Title
                    }));
                    setEvents(formattedEvents);
                } catch (error) {
                    console.error('Error fetching events:', error);
                }
            };

            fetchEvents();
        }
    }, [isOpen, homeId, pb]);

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 flex items-center justify-center z-50">
            <div className="bg-black bg-opacity-50 absolute inset-0" onClick={onClose}></div>
            <div className="bg-white rounded-lg shadow-lg z-50 max-w-5xl w-full p-8 space-y-6">
                <h2 className="text-2xl font-bold text-gray-800">Schedule for Home</h2>
                <Calendar
                    localizer={localizer}
                    events={events}
                    startAccessor="start"
                    endAccessor="end"
                    defaultView="week"
                    view={view}
                    style={{ height: 500 }}
                    className="custom-calendar"
                    components={{
                        toolbar: CustomToolbar,
                    }}
                />
                <div className="flex justify-end">
                    <GradientButton
                        className="mt-4 bg-gradient-to-r from-blue-500 to-purple-600 text-white py-2 px-6 rounded-lg hover:from-blue-600 hover:to-purple-700 transition-transform transform hover:scale-105"
                        onClick={onClose}
                    >
                        Close
                    </GradientButton>
                </div>
            </div>
        </div>
    );
};

export default CalendarModal;
