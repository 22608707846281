import React, { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import PocketBaseContext from '../../PocketBaseContext';
import { useContext } from 'react';

const Registration = ({ open, handleClose }) => {
    const [formData, setFormData] = useState({
        email: '',
        password: ''
    });

    const pb = useContext(PocketBaseContext);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            // Login
            const authData = await pb.collection('users').authWithPassword(formData.email, formData.password);
            // console.log('User logged in:', authData);
            handleClose();
        } catch (error) {
            console.error('Error during authentication:', error);
            console.error('Detailed error:', error.data); // Log additional error details if available
        }
    };

    // const handleToggle = () => {
    //     setIsLogin(!isLogin);
    // };

    if (!open) return null;

    return (
        <div className="w-full h-full fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className="w-full h-full bg-white p-8 rounded-md relative">
                {/* <button
                    className="absolute top-4 right-4 text-gray-600"
                    onClick={handleClose}
                >
                    <CloseIcon />
                </button> */}
                <div className="flex flex-col items-center">
                    <img src="/logo.png" alt="Logo" className="w-20 h-20 mb-4" />
                    <h2 className="text-xl font-semibold text-center">
                        Log In
                    </h2>
                </div>
                <form className="mt-4 space-y-4 " onSubmit={handleSubmit}>
                    <div>
                        <label className="block text-gray-700">Email</label>
                        <input
                            type="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            className="w-full mt-1 p-2 border rounded-md"
                            placeholder="Email"
                        />
                    </div>
                    <div>
                        <label className="block text-gray-700">Password</label>
                        <input
                            type="password"
                            name="password"
                            value={formData.password}
                            onChange={handleChange}
                            className="w-full mt-1 p-2 border rounded-md"
                            placeholder="Password"
                        />
                    </div>
                    <div className='gradient-border-container w-full'>
                        <button
                            type="submit"
                            className="w-full p-2 rounded-md text-gray-500 hover:text-transparent bg-clip-text hover:bg-gradient-to-r from-[#EC6227] via-[#E80458] to-[#5900B3] transition-all duration-300"
                        >
                            Log In
                        </button>
                    </div>
                </form>
                {/* <button
                    onClick={handleToggle}
                    className="mt-4 w-full text-center text-gray-400 underline hover:text-blue-400"
                >
                    {isLogin ? "Don't have an account? Sign Up" : "Already have an account? Log In"}
                </button> */}
            </div>
        </div>
    );
};

export default Registration;
