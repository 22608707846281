import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import PocketBaseContext from '../../PocketBaseContext';
import GradientButton from '../Reusables/gradient_button';

const LogoutButton = ({ onLogout }) => {
    const navigate = useNavigate();
    const pb = useContext(PocketBaseContext)

    const handleLogout = () => {
        pb.authStore.clear();
        navigate('/');
    };

    return (
        <GradientButton
            onClick={handleLogout}
            className="!flex !justify-center !items-center"
            style={{ justifyContent: 'center', alignItems: 'center' }}
        >
            Logout
        </GradientButton>
    );
};

export default LogoutButton;
