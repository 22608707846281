import React, { useContext, useState, useEffect } from 'react';
import { Calendar, momentLocalizer, Views } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import PocketBaseContext from '../../../PocketBaseContext';
import GradientButton from '../../Reusables/gradient_button';

const localizer = momentLocalizer(moment);

const CalendarComponent = () => {
    const [events, setEvents] = useState([]);
    const [homes, setHomes] = useState([]); // Store available homes
    const [selectedHome, setSelectedHome] = useState(''); // Store selected home ID
    const [view, setView] = useState(Views.WEEK); // State to manage the current view
    const pb = useContext(PocketBaseContext);
    const user = pb.authStore.model; // Current logged-in user

    // Fetch available homes from PocketBase
    useEffect(() => {
        pb.autoCancellation(false);
        const fetchHomes = async () => {
            const result = await pb.collection('Homes').getFullList();
            setHomes(result);
        };
        fetchHomes();
    }, [pb]);

    // Fetch events when selectedHome changes
    useEffect(() => {
        const fetchEvents = async () => {
            if (selectedHome) {
                const fetchedEvents = await pb.collection('Events').getFullList({
                    filter: `Home_Id = "${selectedHome}"`,
                    sort: 'Start',
                });
                setEvents(fetchedEvents.map(event => ({
                    id: event.id,
                    title: event.Title,
                    start: new Date(event.Start),
                    end: new Date(event.End),
                    home_id: event.Home_Id,
                    user_id: event.User_Id
                })));
            } else {
                setEvents([]);
            }
        };
        fetchEvents();
    }, [selectedHome, pb]);

    // Handle creating a new event
    const handleSelectSlot = async ({ start, end }) => {
        if (!selectedHome) {
            alert('Please select a home before scheduling an event.');
            return;
        }

        const title = prompt('New Event name');
        if (title) {
            const newEvent = { start, end, title, home_id: selectedHome, user_id: user.id };

            try {
                // Save the event to PocketBase
                const createdEvent = await pb.collection('Events').create({
                    Start: start.toISOString(),
                    End: end.toISOString(),
                    Title: title,
                    Home_Id: selectedHome,
                    User_Id: user.id,
                });

                // Update local state with the new event
                setEvents(prevEvents => [...prevEvents, { ...newEvent, id: createdEvent.id }]);

            } catch (error) {
                console.error('Error creating event:', error);
                alert('Failed to create event. Please try again.');
            }
        }
    };

    // Custom Toolbar
    const CustomToolbar = ({ onNavigate, label }) => {
        return (
            <div className="rbc-toolbar">
                <div className="rbc-btn-group">
                    <GradientButton onClick={() => onNavigate('PREV')}>Previous</GradientButton>
                    <GradientButton onClick={() => onNavigate('TODAY')}>Today</GradientButton>
                    <GradientButton onClick={() => onNavigate('NEXT')}>Next</GradientButton>
                </div>
                <span className="rbc-toolbar-label">{label}</span>
                <div className="rbc-btn-group">
                    <GradientButton onClick={() => setView(Views.MONTH)}>Month</GradientButton>
                    <GradientButton onClick={() => setView(Views.WEEK)}>Week</GradientButton>
                    <GradientButton onClick={() => setView(Views.DAY)}>Day</GradientButton>
                </div>
            </div>
        );
    };

    return (
        <div className="min-w-full space-y-6">
            <h2 className="text-2xl font-bold text-gray-800 mb-4">Schedule an Event</h2>

            {/* Home selection dropdown */}
            <div className="mb-4">
                <label htmlFor="home" className="block mb-2 font-medium text-gray-700">Select Home:</label>
                <select
                    id="home"
                    className="p-2 border border-gray-300 rounded-md"
                    value={selectedHome}
                    onChange={(e) => setSelectedHome(e.target.value)}
                >
                    <option value="">-- Select a Home --</option>
                    {homes.map((home) => (
                        <option key={home.id} value={home.id}>
                            {home.Address} {/* Assuming each home has an 'Address' field */}
                        </option>
                    ))}
                </select>
            </div>

            {/* React Big Calendar */}
            <Calendar
                localizer={localizer}
                events={events}
                startAccessor="start"
                endAccessor="end"
                view={view} // Pass the view state here
                selectable
                onSelectSlot={handleSelectSlot} // Allow selecting time slots
                style={{ height: 600, width: '100%' }} // Make the calendar responsive
                className="custom-calendar"
                components={{
                    toolbar: CustomToolbar,
                }}
            />
        </div>
    );
};

export default CalendarComponent;
