import React from 'react';
import Footer from './components/footer/footer';
import Header from './components/Header/header';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import MapComponent from './components/Map/map';
import PocketBaseProvider from './PocketBaseProvider';
import Dashboard from './components/Dashboard/dashboard';
import PropertyListing from './components/Individual_Property/listing';
import LandingPage from './components/Landing_Page/landingpage';
import Journal from './components/Journal/journal';
import './App.css'

const MainLayout = ({ children }) => (
  <div className="flex flex-col min-h-screen">
    <PocketBaseProvider>
      <Header />
      <main className="flex-grow pt-[var(--header-height)]">
        {children}
      </main>
      <Footer />
    </PocketBaseProvider>
  </div>
);

const NoHeaderFooterLayout = ({ children }) => (
  <div className="flex flex-col min-h-screen">
    <PocketBaseProvider>
      <main className="flex-grow">
        {children}
      </main>
    </PocketBaseProvider>
  </div>
);

const router = createBrowserRouter([
  {
    path: "/",
    element: <NoHeaderFooterLayout><LandingPage /></NoHeaderFooterLayout>,
  },
  {
    path: "/map",
    element: <MainLayout><MapComponent /></MainLayout>,
  },
  {
    path: "/dashboard/*",
    element: <MainLayout><Dashboard /></MainLayout>,
  },
  {
    path: "/listing/:propertyId",
    element: <MainLayout><PropertyListing /></MainLayout>,
  },
  {
    path: "/about",
    element: <NoHeaderFooterLayout><LandingPage /></NoHeaderFooterLayout>,
  },
  {
    path: "/journal",
    element: <MainLayout><Journal /></MainLayout>,
  }
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
