import React, { useState } from 'react';
import { IconButton, Select, MenuItem } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import GradientButton from '../../Reusables/gradient_button';
import GradientSlider from '../../Reusables/gradient_slider';
import PropertyStatusDropdown from '../../Reusables/property_status';

export default function FilterBar({
    priceRange = [0, 1000000],
    setPriceRange,
    bedroomsRange = [1, 5],
    setBedroomsRange,
    bathrooms = [1, 5],
    setBathrooms,
    additionalFilters,
    setAdditionalFilters,
    onFilterChange,
    propertyStatus,
    setPropertyStatus,
}) {
    const [open, setOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');

    const handlePriceChange = (newRange) => {
        setPriceRange(newRange);
        onFilterChange();
    };

    const handleBedroomsChange = (newValue) => {
        setBedroomsRange(newValue);
        onFilterChange();
    };

    const handleBathroomChange = (newValue) => {
        setBathrooms(newValue);
        onFilterChange();
    };

    const handleAdditionalFilterChange = (key) => {
        setAdditionalFilters((prev) => {
            const newFilters = { ...prev, [key]: !prev[key] };
            onFilterChange();
            return newFilters;
        });
    };

    const handleStatusChange = (status) => {
        console.log('Selected status:', status);
        setPropertyStatus(status);
    };

    const valuetext = (value) => {
        return `${value} Bedrooms`;
    };

    return (
        <div className="w-full mx-auto p-4 bg-background shadow-lg rounded-lg flex flex-col mb-3">
            {/* Search and Property Type */}
            <div className="flex justify-between mb-4 items-center">
                <PropertyStatusDropdown onSelectStatus={handleStatusChange} className="flex-grow-0 flex-shrink-0" />
                <input
                    type="text"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    placeholder="Search properties..."
                    className="p-2 border rounded flex-grow focus:border-primary focus:outline-none"
                />
            </div>

            {/* Sliders for Price, Bedrooms, Bathrooms */}
            <div className="flex gap-8 w-full mb-4">
                <div className="flex-1">
                    <label htmlFor="price-range" className="block font-medium text-sm mb-2">
                        Price Range
                    </label>
                    <GradientSlider
                        value={priceRange} // This should be an array [minPrice, maxPrice]
                        onChange={handlePriceChange}
                        min={0}
                        max={10000000}
                        step={10000}
                        aria-labelledby="price-range"
                    />
                    <div className="flex justify-between mt-1 text-xs text-muted">
                        <span>${priceRange[0].toLocaleString()}</span> {/* Display min price */}
                        <span>${priceRange[1].toLocaleString()}</span> {/* Display max price */}
                    </div>
                </div>

                <div className="flex-1" style={{ marginTop: '-1px' }}>
                    <label htmlFor="bedroom-range" className="block font-medium text-sm mb-2">
                        Bedrooms
                    </label>
                    <GradientSlider
                        value={bedroomsRange} // This should be an array [minPrice, maxPrice]
                        onChange={handleBedroomsChange}
                        min={0}
                        max={10}
                        step={1}
                        aria-labelledby="price-range"
                        thumbProps={{
                            style: {
                                height: '24px', // Adjust thumb height to be above the bar
                                width: '24px', // Adjust thumb width to be above the bar
                                marginTop: '-12px', // Adjust margin top to position thumb above the bar
                                marginLeft: '-12px', // Adjust margin left to position thumb above the bar
                            },
                        }}
                    />
                    <div className="flex justify-between mt-1 text-xs text-muted">
                        <span>{bedroomsRange[0]}</span> {/* Display min price */}
                        <span>{bedroomsRange[1]}</span> {/* Display max price */}
                    </div>
                </div>

                <div className="flex-1" style={{ marginTop: '-1px' }}>
                    <label htmlFor="bathrooms" className="block font-medium text-sm mb-2">
                        Bathrooms
                    </label>
                    <GradientSlider
                        value={bathrooms} // This should be an array [minPrice, maxPrice]
                        onChange={handleBathroomChange}
                        min={0}
                        max={10}
                        step={1}
                        aria-labelledby="price-range"

                    />
                    <div className="flex justify-between mt-1 text-xs text-muted">
                        <span>{bathrooms[0]}</span> {/* Display min price */}
                        <span>{bathrooms[1]}</span> {/* Display max price */}
                    </div>
                </div>


                {/* Additional Filters */}
                <div className="flex flex-col m-3">
                    <GradientButton
                        className='w-full flex justify-center items-center p-2'
                        onClick={() => setOpen(!open)}
                    >
                        <div className='flex justify-center mr-4'>
                            {open ? 'Less Filters' : 'More Filters'}

                            <IconButton aria-label="filter list" size="small" className="text-white ml-2">
                                <FilterListIcon />
                            </IconButton>
                        </div>
                    </GradientButton>
                </div>
            </div>


            {/* Toggleable additional filters */}
            {open && (
                <div className="mt-4 bg-gray-50 p-4 rounded-md shadow-inner">
                    <h3 className="font-semibold text-lg mb-3">Additional Filters</h3>
                    <div className="grid grid-cols-2 gap-4">
                        {[
                            { id: 'parking', label: 'Parking' },
                            { id: 'pool', label: 'Pool' },
                            { id: 'garden', label: 'Garden' },
                            { id: 'gym', label: 'Gym' },
                            { id: 'petFriendly', label: 'Pet Friendly' },
                            { id: 'smokingAllowed', label: 'Smoking Allowed' },
                            { id: 'furnished', label: 'Furnished' },
                            { id: 'newConstruction', label: 'New Construction' }
                        ].map((filter) => (
                            <div className="flex items-center space-x-2" key={filter.id}>
                                <input
                                    type="checkbox"
                                    id={filter.id}
                                    checked={additionalFilters[filter.id]}
                                    onChange={() => handleAdditionalFilterChange(filter.id)}
                                    className="h-4 w-4 rounded focus:ring-primary focus:ring-offset-1"
                                />
                                <label htmlFor={filter.id} className="text-sm font-medium">
                                    {filter.label}
                                </label>
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
}
