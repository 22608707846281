import React, { useState, useRef, useContext } from 'react';
import GradientButton from '../../Reusables/gradient_button';
import PocketBaseContext from '../../../PocketBaseContext';

const Geocode_token = process.env.REACT_APP_GEOCODE_API;

const ProgressModal = ({ open, onClose }) => {
    const [step, setStep] = useState(0);
    const [formData, setFormData] = useState({
        Address: '',
        Price: 0,
        Location: '',
        Description: '',
        Latitude: '',
        Longitude: '',
        Bedrooms: '',
        Bathrooms: '',
        SQFT: '',
        Image: [],
    });
    const modalRef = useRef(null);
    const pb = useContext(PocketBaseContext);

    const handleInputChange = async (e) => {
        const { name, value, type } = e.target;
        const updatedValue = type === 'number' ? parseFloat(value) : value;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: updatedValue,
        }));

        // If the Address field is changed, fetch geocode data
        if (name === 'Address') {
            try {
                const encodedAddress = encodeURIComponent(value);
                const response = await fetch(`https://geocode.maps.co/search?q=${encodedAddress}&api_key=${Geocode_token}`);
                const data = await response.json();
                if (data && data.length > 0) {
                    const { lat, lon } = data[0];
                    setFormData(prevFormData => ({
                        ...prevFormData,
                        Latitude: lat,
                        Longitude: lon,
                    }));
                }
            } catch (error) {
                console.error('Error fetching geocode data:', error);
            }
        }
    };

    const handleFileChange = (e) => {
        const { files } = e.target;
        if (files.length > 0) {
            const fileArray = Array.from(files);
            setFormData((prevFormData) => ({
                ...prevFormData,
                Image: fileArray,
            }));
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        try {
            const formToSubmit = new FormData();
            for (const key in formData) {
                if (key === 'Image' && formData[key].length > 0) {
                    for (const file of formData[key]) {
                        formToSubmit.append('Image', file);
                    }
                } else {
                    formToSubmit.append(key, formData[key]);
                }
            }

            formToSubmit.forEach((value, key) => {
                console.log(`${key}:`, value);
            });

            const createdRecord = await pb.collection('Homes').create(formToSubmit);

            setFormData({
                Address: '',
                Price: 0,
                Location: '',
                Description: '',
                Latitude: '',
                Longitude: '',
                Bedrooms: '',
                Bathrooms: '',
                SQFT: '',
                Image: [],
            });

            handleNext();
            onClose();
        } catch (err) {
            window.alert('Error: ' + err.message);
        }
    };

    const steps = [
        { title: 'Step 1', fields: ['Address', 'Latitude', 'Longitude', 'Bedrooms', 'Bathrooms', 'SQFT'] },
        { title: 'Step 2', fields: ['Price', 'Images'] },
        { title: 'Step 3', fields: ['Description'] },
    ];
    const totalSteps = steps.length;
    const progress = (step / (totalSteps - 1)) * 100;

    const handleNext = () => {
        if (step < totalSteps - 1) {
            setStep(step + 1);
        }
    };

    const handleBack = () => {
        if (step > 0) {
            setStep(step - 1);
        }
    };

    const handleClickOutside = (e) => {
        if (modalRef.current && !modalRef.current.contains(e.target)) {
            onClose();
        }
    };

    if (!open) return null;

    return (
        <div
            className="fixed inset-0 flex items-center justify-center z-50 bg-gray-800 bg-opacity-50"
            onClick={handleClickOutside}
        >
            <div
                ref={modalRef}
                className="bg-white p-8 rounded-lg shadow-lg w-full max-w-3xl h-[700px]"
                onClick={(e) => e.stopPropagation()}
            >
                <h2 className="text-2xl font-semibold mb-6">{steps[step].title}</h2>
                <div className="w-full h-2 bg-gray-200 rounded-full mb-6">
                    <div
                        className="h-2 bg-blue-500 rounded-full"
                        style={{ width: `${progress}%` }}
                    ></div>
                </div>
                <form onSubmit={handleSubmit}>
                    {steps[step].fields.map((field, index) => (
                        <div key={index} className="mb-4">
                            {field === 'Images' ? (
                                <input
                                    type="file"
                                    name="Images"
                                    accept="image/*"
                                    className="w-full p-3 border border-gray-300 rounded mb-4 text-lg"
                                    onChange={handleFileChange}
                                    multiple
                                />
                            ) : (
                                <input
                                    type={field === 'Price' ? 'number' : 'text'}
                                    name={field}
                                    placeholder={field}
                                    className="w-full p-3 border border-gray-300 rounded mb-4 text-lg"
                                    value={formData[field]}
                                    onChange={handleInputChange}
                                    required
                                />
                            )}
                        </div>
                    ))}
                    <div className="flex justify-between">
                        <GradientButton
                            type="button"
                            className="px-5 py-3 bg-gray-300 text-gray-700 rounded"
                            disabled={step === 0}
                            onClick={handleBack}
                        >
                            Back
                        </GradientButton>
                        <GradientButton
                            type="button"
                            className="px-5 py-3 bg-blue-500 text-white rounded"
                            onClick={step === totalSteps - 1 ? handleSubmit : handleNext}
                        >
                            {step === totalSteps - 1 ? 'Add Property' : 'Next'}
                        </GradientButton>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default ProgressModal;
