export default function GradientButton({ href, onClick, children }) {
    return (
        <div className="gradient-border-container cursor-pointer">
            <a
                href={href}
                onClick={onClick}
                className="text-xl font-medium text-gray-500 hover:text-transparent bg-clip-text hover:bg-gradient-to-r from-[#EC6227] via-[#E80458] to-[#5900B3] transition-all duration-300"
            >
                {children}
            </a>
        </div >
    );
}