import React from 'react';
import PocketBaseContext from './PocketBaseContext';
import PocketBase from 'pocketbase';

const pb = new PocketBase(process.env.REACT_APP_PB_URL);

const PocketBaseProvider = ({ children }) => {
    return (
        <PocketBaseContext.Provider value={pb}>
            {children}
        </PocketBaseContext.Provider>
    );
};

export default PocketBaseProvider;