import React, { useContext, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import PocketBaseContext from '../../PocketBaseContext';
import GradientButton from '../Reusables/gradient_button';
import Registration from '../Registration/registration';

export default function Header() {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(true);
    const [showError, setShowError] = useState(false);
    const [errorOpacity, setErrorOpacity] = useState(1); // Track opacity for fade-out
    const isDashboard = window.location.pathname === '/dashboard';
    const pb = useContext(PocketBaseContext);
    const user = pb.authStore.model;
    const navigate = useNavigate();
    const location = useLocation();

    const handleButtonClick = (e) => {
        e.preventDefault(); // Prevent default link behavior
        if (!user) {
            setShowError(true);
            setErrorOpacity(1); // Reset opacity
            setTimeout(() => {
                setErrorOpacity(0); // Start fade-out
                setTimeout(() => setShowError(false), 1000); // Hide completely after fade-out
            }, 5000); // Show error for 5 seconds
        } else {
            // Handle navigation here, if needed
            window.location.href = isDashboard ? '/' : '/dashboard';
        }
    };

    const handleModalOpen = () => {
        setIsModalOpen(true);
    };

    const handleModalClose = () => {
        setIsModalOpen(false);
    };

    return (
        <header className="bg-white border-gray-200 fixed top-0 left-0 right-0 z-50 h-[var(--header-height)]">
            <div className="container mx-auto px-4 sm:px-6 lg:px-8">
                <div className="flex justify-between items-center py-4 md:justify-start md:space-x-10">
                    {/* Logo */}
                    {/* Logo */}
                    <div className="flex flex-col justify-between lg:w-0 lg:flex-1">
                        <a href="/" className="flex space-x-2">
                            <svg
                                width="40"
                                height="40"
                                viewBox="0 0 349 360"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M162.878 1.34299C159.303 2.31299 154.353 4.31199 151.878 5.78599C149.403 7.25899 117.459 28.406 80.8906 52.779C18.6766 94.245 14.1036 97.49 9.74056 103.269C7.17556 106.665 4.01956 112.065 2.72756 115.269L0.377558 121.093L0.0755557 218.593C-0.140444 288.229 0.0975564 317.808 0.907556 322.093C4.33356 340.226 19.1426 355.299 36.7636 358.588C44.6946 360.068 305.078 359.954 312.079 358.468C329.713 354.723 344.482 339.296 347.794 321.159C349.298 312.926 349.298 132.26 347.794 124.027C346.292 115.801 341.724 106.68 335.934 100.344C333.038 97.176 325.806 91.615 318.21 86.716C311.153 82.164 301.301 75.661 296.319 72.266C282.234 62.669 227.543 26.235 210.09 14.822C201.452 9.17298 191.978 3.65199 189.038 2.55199C181.318 -0.337014 170.852 -0.820014 162.878 1.34299ZM182.567 20.504C186.241 21.557 316.788 108.261 321.562 112.818C323.312 114.489 325.678 118.025 326.818 120.676C328.869 125.443 328.892 126.561 328.888 222.777L328.885 320.059L326.32 325.3C324.91 328.183 321.92 332.113 319.676 334.034C311.942 340.654 312.078 340.641 255.123 340.352L203.94 340.093L265.909 275.601C299.992 240.13 327.878 210.768 327.878 210.351C327.878 209.046 222.145 209.433 217.736 210.754C212.561 212.304 206.032 218.044 203.764 223.037C202.045 226.823 201.92 230.876 201.9 283.866L201.878 340.638L120.128 340.366C42.2006 340.106 38.2136 340.005 34.8776 338.206C28.5456 334.792 24.3616 330.852 21.8516 325.936L19.3776 321.093L19.1126 224.254C18.9356 159.42 19.1926 126.137 19.8906 123.546C21.9576 115.87 26.2756 111.989 51.4596 95.173C123.47 47.091 160.808 22.521 163.905 21.178C167.81 19.484 177.759 19.125 182.567 20.504Z"
                                    fill="url(#paint0_linear_6_17)"
                                />
                                <defs>
                                    <linearGradient
                                        id="paint0_linear_6_17"
                                        x1="45.8776"
                                        y1="89.593"
                                        x2="332.878"
                                        y2="324.593"
                                        gradientUnits="userSpaceOnUse"
                                    >
                                        <stop offset="0.12" stopColor="#EC6227" />
                                        <stop offset="0.635" stopColor="#E80458" />
                                        <stop offset="1" stopColor="#5900B3" />
                                    </linearGradient>
                                </defs>
                            </svg>
                            <span className="text-4xl font-bold mt-1 ml-1 text-gray-900">Realti</span>
                        </a>
                    </div>

                    {/* Mobile menu button */}
                    <div className="-mr-2 -my-2 md:hidden">
                        <button
                            type="button"
                            className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                            onClick={() => setIsMenuOpen(!isMenuOpen)}
                        >
                            <span className="sr-only">Open menu</span>
                            <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
                            </svg>
                        </button>
                    </div>

                    {/* Desktop menu */}
                    <nav className="hidden md:flex space-x-10">
                        {user ? (
                            <HeaderLink href="/dashboard/profile">
                                Hi, {capitalize(user.name)}
                            </HeaderLink>
                        ) : (
                            <HeaderLink href="#" onClick={handleModalOpen}>
                                Log In or Sign Up
                            </HeaderLink>
                        )}
                    </nav>

                    {/* CTA */}
                    <div className="hidden md:flex items-center justify-end md:flex-1 lg:w-0">
                        <div className="gradient-border-container">
                            <GradientButton href="#" onClick={() => navigate(location.pathname !== '/map' ? '/map' : '/dashboard')}>
                                {location.pathname !== '/map' ? 'Map' : 'Dashboard'}
                            </GradientButton>
                        </div>
                    </div>
                </div>
            </div>

            {/* Error Message */}
            {showError && (
                <div className={`bg-red-100 text-red-800 border border-red-500 p-4 text-center transition-opacity duration-500`} style={{ opacity: errorOpacity }}>
                    <h1 className="text-lg font-bold">Please log in to access this feature.</h1>
                </div>
            )}

            {/* Mobile menu */}
            {isMenuOpen && (
                <div className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden">
                    <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
                        {/* Mobile menu content */}
                    </div>
                </div>
            )}

            {/* Registration Modal */}
            <Registration open={isModalOpen} handleClose={handleModalClose} />
        </header>
    );
}

function HeaderLink({ href, onClick, children }) {
    return (
        <a
            href={href}
            onClick={onClick}
            className="text-xl font-medium text-gray-500 hover:text-transparent bg-clip-text hover:bg-gradient-to-r from-[#EC6227] via-[#E80458] to-[#5900B3] transition-all duration-300"
        >
            {children}
        </a>
    );
}

function capitalize(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}
