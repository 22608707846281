import React, { useState, useEffect } from 'react';

const GradientRangeSlider = ({ min = 0, max = 100, step = 1, minValue, maxValue, onChange }) => {
    const [minSliderValue, setMinSliderValue] = useState(minValue || min);
    const [maxSliderValue, setMaxSliderValue] = useState(maxValue || max);

    useEffect(() => {
        // Ensure incoming values are within bounds
        if (minValue !== undefined) {
            setMinSliderValue(Math.min(Math.max(minValue, min), maxSliderValue));
        }
        if (maxValue !== undefined) {
            setMaxSliderValue(Math.max(Math.min(maxValue, max), minSliderValue));
        }
    }, [minValue, maxValue, min, max]);

    const handleMinSliderChange = (e) => {
        let newValue = Number(e.target.value);
        newValue = Math.min(Math.max(newValue, min), maxSliderValue); // Ensure min slider is <= max
        setMinSliderValue(newValue);
        if (onChange) {
            onChange([newValue, maxSliderValue]);
        }
    };

    const handleMaxSliderChange = (e) => {
        let newValue = Number(e.target.value);
        newValue = Math.max(Math.min(newValue, max), minSliderValue); // Ensure max slider is >= min
        setMaxSliderValue(newValue);
        if (onChange) {
            onChange([minSliderValue, newValue]);
        }
    };

    // Calculate the fill percentages for the track
    const calculateFillPercentage = () => {
        const range = max - min;
        const minPercentage = ((minSliderValue - min) / range) * 100;
        const maxPercentage = ((maxSliderValue - min) / range) * 100;
        return { minPercentage, maxPercentage };
    };

    const { minPercentage, maxPercentage } = calculateFillPercentage();

    return (
        <div className="w-full flex flex-col items-center">
            <div className="relative w-full h-8">
                {/* Track background */}
                <div className="absolute top-1/2 transform -translate-y-1/2 w-full h-2 rounded-lg bg-gray-200" />
                {/* Filled track */}
                <div
                    className="absolute top-1/2 transform -translate-y-1/2 h-2 rounded-lg"
                    style={{
                        left: `${minPercentage}%`,
                        right: `${100 - maxPercentage}%`,
                        background: 'linear-gradient(to right, #EC6227, #E80458, #5900B3)',
                    }}
                />
                {/* Min Slider */}
                <input
                    type="range"
                    min={min}
                    max={max}
                    step={step}
                    value={minSliderValue}
                    onChange={handleMinSliderChange}
                    className="absolute w-full h-full appearance-none pointer-events-auto"
                    style={{
                        zIndex: minSliderValue === maxSliderValue ? '5' : '3',
                        // Remove marginTop and marginLeft adjustments
                    }}
                />
                {/* Max Slider */}
                <input
                    type="range"
                    min={min}
                    max={max}
                    step={step}
                    value={maxSliderValue}
                    onChange={handleMaxSliderChange}
                    className="absolute w-full h-full appearance-none pointer-events-auto"
                    style={{
                        zIndex: '4',
                        // Remove marginTop and marginLeft adjustments
                    }}
                />
            </div>

            <style jsx>{`
    input[type="range"] {
        -webkit-appearance: none;
        appearance: none;
        width: 100%;
        height: 8px;
        background: transparent; /* Hide the default track */
    }

    input[type="range"]::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        width: 18px;
        height: 18px;
        background-color: #fff;
        border: 2px solid #E80458;
        border-radius: 50%;
        cursor: pointer;
        pointer-events: auto; /* Enable pointer events for thumbs */
        transform: translateY(65%);
        
        /* Remove or adjust transform */
    }

    input[type="range"]::-moz-range-thumb,
    input[type="range"]::-ms-thumb {
        width: 16px;
        height: 16px;
        background-color: #fff;
        border: 2px solid #E80458;
        border-radius: 50%;
        cursor: pointer;
        pointer-events: auto;
        /* Remove or adjust transform */
    }
`}</style>

        </div>
    );
};

export default GradientRangeSlider;
