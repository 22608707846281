import React, { useContext, useState, useEffect } from 'react';
import MessageIcon from '@mui/icons-material/Message';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import PocketBaseContext from '../../../PocketBaseContext';
import { useRef } from 'react';

// Fetch Conversations
const fetchConversations = async (pb) => {
    pb.autoCancellation(false);
    const conversations = await pb.collection('conversations').getFullList({ expand: 'messages' });
    console.log("convo", conversations)
    return conversations.map(convo => ({
        id: convo.id,
        name: convo.Participants.join(', '), // Assuming Participants is an array of participant names or IDs
        lastMessage: convo.expand?.messages?.[0]?.Text || 'No messages yet',
        unreadCount: convo.expand?.messages?.filter(msg => !msg.isRead).length || 0, // If there's an `isRead` field to track unread messages
    }));
};

// Fetch Messages for a Specific Conversation
const fetchMessages = async (pb, conversationId) => {
    pb.autoCancellation(false);
    try {
        const messages = await pb.collection('Messages').getFullList({
            filter: `Conversation_Id = "${conversationId}"`, // Ensure this matches your schema
            sort: '-created', // Ensure this matches your schema
        })
        console.log(messages)
        return messages.map(msg => ({
            id: msg.id,
            author: msg.Sender_Id, // Adjust case if necessary
            content: msg.Text, // Adjust case if necessary
            timestamp: new Date(msg.created).toLocaleTimeString() // Adjust case if necessary
        }));
    } catch (error) {
        console.error("Error fetching messages:", error?.data || error);

        throw error; // Or handle the error as appropriate
    }
};


// Conversation Row Component
const ConversationRow = ({ conversation, onClick, isActive }) => (
    <div
        className={`flex items-center justify-between p-4 cursor-pointer transition-all duration-300 ${isActive ? 'bg-blue-100' : 'bg-white'} hover:bg-blue-50 shadow-sm rounded-md mb-2`}
        onClick={onClick}
    >
        <div className="flex items-center">
            <MessageIcon className="w-7 h-7 text-blue-500 mr-4" />
            <div>
                <h3 className="font-semibold text-gray-900">{conversation.name}</h3>
                <p className="text-sm text-gray-600">{conversation.lastMessage}</p>
            </div>
        </div>
        {conversation.unreadCount > 0 && (
            <span className="bg-blue-500 text-white text-xs font-bold px-2 py-1 rounded-full">
                {conversation.unreadCount}
            </span>
        )}
    </div>
);


const ThreadView = ({ messages, onClose, onSendMessage }) => {
    const [newMessage, setNewMessage] = useState('');
    const messagesEndRef = useRef(null);
    const pb = useContext(PocketBaseContext);

    useEffect(() => {
        // Scroll to the bottom whenever messages change
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, [messages]);

    const handleSendMessage = () => {
        if (newMessage.trim()) {
            onSendMessage(newMessage.trim());
            setNewMessage('');
        }
    };

    return (
        <div className="flex flex-col h-full bg-gray-50 rounded-lg shadow-lg overflow-hidden">
            <div className="flex items-center justify-between p-4 border-b bg-white">
                <h2 className="text-xl font-semibold text-gray-900">Conversation</h2>
                <button onClick={onClose} className="text-gray-500 hover:text-gray-700 transition-colors">
                    <ChevronRightIcon className="w-6 h-6" />
                </button>
            </div>
            <div className="flex-1 overflow-y-auto p-4">
                {messages.map(message => (
                    <div key={message.id} className="mb-6">
                        <div className="flex items-center mb-1">
                            <AccountCircleIcon className="w-7 h-7 text-blue-500 mr-3" />
                            <span className="font-semibold text-gray-900 mr-2">{message.author}</span>
                            <span className="text-xs text-gray-500">{message.timestamp}</span>
                        </div>
                        <p className="ml-10 text-gray-800 bg-white p-3 rounded-lg shadow-sm">{message.content}</p>
                    </div>
                ))}
                <div ref={messagesEndRef} />
            </div>
            <div className="p-4 bg-white border-t">
                <div className="flex items-center">
                    <input
                        type="text"
                        className="flex-1 border rounded-lg px-4 py-2 mr-2"
                        placeholder="Type your message..."
                        value={newMessage}
                        onChange={(e) => setNewMessage(e.target.value)}
                        onKeyDown={(e) => e.key === 'Enter' && handleSendMessage()}
                    />
                    <button
                        className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600"
                        onClick={handleSendMessage}
                    >
                        Send
                    </button>
                </div>
            </div>
        </div>
    );
};


export default function Messages() {
    const [activeConversation, setActiveConversation] = useState(null);
    const [conversations, setConversations] = useState([]);
    const [messages, setMessages] = useState([]);
    const pb = useContext(PocketBaseContext);
    const user = pb.authStore.model;

    console.log(user)

    useEffect(() => {
        const loadConversations = async () => {
            const convos = await fetchConversations(pb);
            setConversations(convos);
        };
        loadConversations();
    }, [pb]);

    useEffect(() => {
        if (activeConversation) {
            const loadMessages = async () => {
                const msgs = await fetchMessages(pb, activeConversation);
                setMessages(msgs);
            };
            loadMessages();
        }
    }, [activeConversation, pb]);

    const handleSendMessage = async (content) => {
        if (content.trim()) {
            console.log(user)
            try {
                if (!user) {
                    console.log(pb.authStore.user)
                    throw new Error('User is not authenticated');
                }

                // Create a new message record in PocketBase
                const newMessage = await pb.collection('Messages').create({
                    Conversation_Id: activeConversation,
                    Sender_Id: user.id, // This is where the issue is if user is not authenticated
                    Text: content,
                });

                // Add the new message to the current message list
                setMessages(prevMessages => [
                    ...prevMessages,
                    {
                        id: newMessage.id,
                        author: newMessage.Sender_Id,
                        content: newMessage.Text,
                        timestamp: new Date(newMessage.created).toLocaleTimeString(),
                    },
                ]);
            } catch (error) {
                console.error("Error sending message:", error.message || error);
            }
        }
    };


    return (
        <div className="flex h-screen bg-white w-[100vh]">
            <div className={`w-full md:w-1/3 ${activeConversation ? 'hidden md:block' : ''}`}>
                {conversations.map(conversation => (
                    <ConversationRow
                        key={conversation.id}
                        conversation={conversation}
                        onClick={() => setActiveConversation(conversation.id)}
                        isActive={activeConversation === conversation.id}
                    />
                ))}
            </div>
            {activeConversation && (
                <div className="w-full md:w-2/3 md:ml-6">
                    <ThreadView
                        messages={messages}
                        onClose={() => setActiveConversation(null)}
                        onSendMessage={handleSendMessage}
                    />
                </div>
            )}
        </div>
    );
}
