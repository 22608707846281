import React, { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom'
import PocketBaseContext from '../../../PocketBaseContext';
import HotelOutlinedIcon from '@mui/icons-material/HotelOutlined';
import BathtubOutlinedIcon from '@mui/icons-material/BathtubOutlined';
import SquareFootOutlinedIcon from '@mui/icons-material/SquareFootOutlined';

const Favorites = () => {
    const [favorites, setFavorites] = useState([]);
    const pb = useContext(PocketBaseContext);
    const user = pb.authStore.model;
    // const homeId = property.id;

    useEffect(() => {
        const fetchFavorites = async () => {
            try {
                const likedHomesResponse = await fetch(`http://localhost:8090/api/collections/Liked_Homes/records?filter=(user_id='${user.id}')`);
                if (!likedHomesResponse.ok) {
                    throw new Error('Failed to fetch liked homes');
                }

                const likedHomesData = await likedHomesResponse.json();
                const homeDetailsPromises = likedHomesData.items.map(async (likedHome) => {
                    const homeResponse = await fetch(`http://localhost:8090/api/collections/Homes/records/${likedHome.home_id}`);
                    if (!homeResponse.ok) {
                        throw new Error(`Failed to fetch home with ID: ${likedHome.home_id}`);
                    }
                    return await homeResponse.json();
                });

                const homeDetails = await Promise.all(homeDetailsPromises);
                setFavorites(homeDetails);
            } catch (error) {
                console.error('Error fetching favorites:', error);
            }
        };

        if (user) {
            fetchFavorites();
        }
    }, [user]);

    return (
        <div className="favorites-container grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {favorites.length === 0 ? (
                <p>You have no saved homes.</p>
            ) : (
                favorites.map((home) => (
                    <Link to={`/listing/${home.id}`}>
                        <div
                            key={home.id}
                            className="relative overflow-hidden cursor-pointer transition-transform duration-300 ease-in-out rounded-lg mb-4 h-72 shadow-lg hover:transform hover:scale-105"
                        >
                            <img
                                src={`http://127.0.0.1:8090/api/files/8rg7vfrwv7amctx/${home.id}/${home.Image[0]}`}
                                alt={home.Address}
                                className="w-full h-full object-cover rounded-lg"
                            />

                            {/* Price tag */}
                            <div className="absolute top-3 right-3 bg-white text-black px-3 py-1 rounded-md shadow-lg">
                                <p className="m-0 font-bold">${home.Price.toLocaleString()}</p>
                            </div>

                            {/* Property info */}
                            <div className="absolute bottom-0 w-full bg-black bg-opacity-30 p-3 text-white hover:bg-opacity-70">
                                <h6 className="m-0 truncate">{home.Address}</h6>

                                <div className="mt-2 flex justify-between space-x-2">
                                    <div className="bg-white text-black p-2 rounded-md flex-1 text-center justify-center">
                                        <div className='flex flex-row items-center justify-center space-x-1'>
                                            <span><HotelOutlinedIcon /></span>
                                            <p className="m-0 text-s">Beds</p>
                                        </div>
                                        <p className="m-0 font-bold">{home.Bedrooms}</p>
                                    </div>
                                    <div className="bg-white text-black p-2 rounded-md flex-1 text-center">
                                        <div className='flex flex-row items-center justify-center space-x-1'>
                                            <span><BathtubOutlinedIcon /></span>
                                            <p className="m-0 text-s">Baths</p>
                                        </div>
                                        <p className="m-0 font-bold">{home.Bathrooms}</p>
                                    </div>
                                    <div className="bg-white text-black p-2 rounded-md flex-1 text-center">
                                        <div className='flex flex-row items-center justify-center space-x-1'>
                                            <span><SquareFootOutlinedIcon /></span>
                                            <p className="m-0 text-s">Sq Ft</p>
                                        </div>
                                        <p className="m-0 font-bold">{home.SQFT.toLocaleString()}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Link>
                ))
            )}
        </div>
    );
};

export default Favorites;
