export default function Footer() {
    const currentYear = new Date().getFullYear();
    return (
        <footer className="w-full bg-white py-12 px-4 md:px-6 border-t">
            <div className="container mx-auto grid grid-cols-1 md:grid-cols-4 gap-8">
                <div className="flex flex-col items-start space-y-4">
                    <a href="/" className="flex items-center space-x-2">
                        <svg
                            width="40"
                            height="40"
                            viewBox="0 0 349 360"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M162.878 1.34299C159.303 2.31299 154.353 4.31199 151.878 5.78599C149.403 7.25899 117.459 28.406 80.8906 52.779C18.6766 94.245 14.1036 97.49 9.74056 103.269C7.17556 106.665 4.01956 112.065 2.72756 115.269L0.377558 121.093L0.0755557 218.593C-0.140444 288.229 0.0975564 317.808 0.907556 322.093C4.33356 340.226 19.1426 355.299 36.7636 358.588C44.6946 360.068 305.078 359.954 312.079 358.468C329.713 354.723 344.482 339.296 347.794 321.159C349.298 312.926 349.298 132.26 347.794 124.027C346.292 115.801 341.724 106.68 335.934 100.344C333.038 97.176 325.806 91.615 318.21 86.716C311.153 82.164 301.301 75.661 296.319 72.266C282.234 62.669 227.543 26.235 210.09 14.822C201.452 9.17298 191.978 3.65199 189.038 2.55199C181.318 -0.337014 170.852 -0.820014 162.878 1.34299ZM182.567 20.504C186.241 21.557 316.788 108.261 321.562 112.818C323.312 114.489 325.678 118.025 326.818 120.676C328.869 125.443 328.892 126.561 328.888 222.777L328.885 320.059L326.32 325.3C324.91 328.183 321.92 332.113 319.676 334.034C311.942 340.654 312.078 340.641 255.123 340.352L203.94 340.093L265.909 275.601C299.992 240.13 327.878 210.768 327.878 210.351C327.878 209.046 222.145 209.433 217.736 210.754C212.561 212.304 206.032 218.044 203.764 223.037C202.045 226.823 201.92 230.876 201.9 283.866L201.878 340.638L120.128 340.366C42.2006 340.106 38.2136 340.005 34.8776 338.206C28.5456 334.792 24.3616 330.852 21.8516 325.936L19.3776 321.093L19.1126 224.254C18.9356 159.42 19.1926 126.137 19.8906 123.546C21.9576 115.87 26.2756 111.989 51.4596 95.173C123.47 47.091 160.808 22.521 163.905 21.178C167.81 19.484 177.759 19.125 182.567 20.504Z"
                                fill="url(#paint0_linear_6_17)"
                            />

                            <defs>
                                <linearGradient
                                    id="paint0_linear_6_17"
                                    x1="45.8776"
                                    y1="89.593"
                                    x2="332.878"
                                    y2="324.593"
                                    gradientUnits="userSpaceOnUse"
                                >
                                    <stop offset="0.12" stopColor="#EC6227" />
                                    <stop offset="0.635" stopColor="#E80458" />
                                    <stop offset="1" stopColor="#5900B3" />
                                </linearGradient>
                            </defs>
                        </svg>
                        <span className="text-2xl font-bold text-gray-900">Realti</span>
                    </a>
                    <p className="text-sm text-gray-500">© {currentYear} Realti. All rights reserved.</p>
                </div>
                <div>
                    <h3 className="text-sm font-semibold text-gray-900 uppercase mb-4">Company</h3>
                    <ul className="space-y-2">
                        <li>
                            <FooterLink href="about">About Us</FooterLink>
                        </li>
                        <li>
                            <FooterLink href="#">Careers</FooterLink>
                        </li>
                        <li>
                            <FooterLink href="#">Contact Us</FooterLink>
                        </li>
                    </ul>
                </div>
                <div>
                    <h3 className="text-sm font-semibold text-gray-900 uppercase mb-4">Resources</h3>
                    <ul className="space-y-2">
                        <li>
                            <FooterLink href="#">Blog</FooterLink>
                        </li>
                        <li>
                            <FooterLink href="#">Newsletter</FooterLink>
                        </li>
                        <li>
                            <FooterLink href="#">Events</FooterLink>
                        </li>
                    </ul>
                </div>
                <div>
                    <h3 className="text-sm font-semibold text-gray-900 uppercase mb-4">Legal</h3>
                    <ul className="space-y-2">
                        <li>
                            <FooterLink href="#">Terms of Service</FooterLink>
                        </li>
                        <li>
                            <FooterLink href="#">Privacy Policy</FooterLink>
                        </li>
                        <li>
                            <FooterLink href="#">Cookie Policy</FooterLink>
                        </li>
                    </ul>
                </div>
            </div>
        </footer>
    );
}

function FooterLink({ href, children }) {
    return (
        <a
            href={href}
            className="inline-block text-gray-500 hover:text-transparent bg-clip-text hover:bg-gradient-to-r from-[#EC6227] via-[#E80458] to-[#5900B3] transition-all duration-300"
        >
            {children}
        </a>
    );
}