import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, BarChart, Bar, PieChart, Pie, Cell } from 'recharts';
// import ImportButton from './prop_button';

const data = [
    { name: 'Jan', uv: 4000, pv: 2400, amt: 2400 },
    { name: 'Feb', uv: 3000, pv: 1398, amt: 2210 },
    { name: 'Mar', uv: 2000, pv: 9800, amt: 2290 },
    { name: 'Apr', uv: 2780, pv: 3908, amt: 2000 },
    { name: 'May', uv: 1890, pv: 4800, amt: 2181 },
    { name: 'Jun', uv: 2390, pv: 3800, amt: 2500 },
    { name: 'Jul', uv: 3490, pv: 4300, amt: 2100 },
];

const pieData = [
    { name: 'Group A', value: 400 },
    { name: 'Group B', value: 300 },
    { name: 'Group C', value: 300 },
    { name: 'Group D', value: 200 },
];

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

const DashboardComponent = () => {
    return (
        <div className="dashboard-container p-4">
            <h1 className="text-3xl font-bold mb-4">Dashboard</h1>

            <div className="kpi-section mb-8">
                <div className="kpi-card bg-white p-4 rounded-lg shadow-md mb-2">
                    <h2 className="text-xl font-semibold">Total Listings</h2>
                    <p className="text-2xl font-bold">1,234</p>
                </div>
                <div className="kpi-card bg-white p-4 rounded-lg shadow-md mb-2">
                    <h2 className="text-xl font-semibold">New Listings This Month</h2>
                    <p className="text-2xl font-bold">123</p>
                </div>
                <div className="kpi-card bg-white p-4 rounded-lg shadow-md mb-2">
                    <h2 className="text-xl font-semibold">Total Sales</h2>
                    <p className="text-2xl font-bold">$1,234,567</p>
                </div>
                <div className="kpi-card bg-white p-4 rounded-lg shadow-md mb-2">
                    <h2 className="text-xl font-semibold">New Users</h2>
                    <p className="text-2xl font-bold">456</p>
                </div>
            </div>

            <div className="charts-section grid grid-cols-1 md:grid-cols-2 gap-8">
                <div className="line-chart bg-white p-4 rounded-lg shadow-md">
                    <h2 className="text-xl font-semibold mb-4">Monthly Listings</h2>
                    <LineChart width={500} height={300} data={data}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Line type="monotone" dataKey="pv" stroke="#8884d8" activeDot={{ r: 8 }} />
                        <Line type="monotone" dataKey="uv" stroke="#82ca9d" />
                    </LineChart>
                </div>

                <div className="bar-chart bg-white p-4 rounded-lg shadow-md">
                    <h2 className="text-xl font-semibold mb-4">Sales by Month</h2>
                    <BarChart width={500} height={300} data={data}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Bar dataKey="pv" fill="#8884d8" />
                        <Bar dataKey="uv" fill="#82ca9d" />
                    </BarChart>
                </div>

                <div className="pie-chart bg-white p-4 rounded-lg shadow-md">
                    <h2 className="text-xl font-semibold mb-4">User Demographics</h2>
                    <PieChart width={400} height={400}>
                        <Pie
                            data={pieData}
                            cx={200}
                            cy={200}
                            labelLine={false}
                            label={({ name, percent }) => `${name} ${(percent * 100).toFixed(0)}%`}
                            outerRadius={80}
                            fill="#8884d8"
                            dataKey="value"
                        >
                            {pieData.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                            ))}
                        </Pie>
                        <Tooltip />
                    </PieChart>
                    {/* <ImportButton /> */}
                </div>
            </div>
        </div>
    );
};

export default DashboardComponent;

