import { useForm, ValidationError } from '@formspree/react';
import GradientButton from '../Reusables/gradient_button';

export default function ContactForm() {
    const [state, handleSubmit] = useForm("xzbnklnl"); // Replace with your Formspree form ID

    // Success message after form is submitted
    if (state.succeeded) {
        return <p className="text-xl bg-gradient-to-r from-[#EC6227] via-[#E80458] to-[#5900B3] text-transparent bg-clip-text font-semibold">Thanks for joining! We'll get back to you soon.</p>;
    }

    return (
        <form onSubmit={handleSubmit} className="w-full max-w-md p-4 rounded-lg">
            {/* Name Field */}
            <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                Name
            </label>
            <input
                id="name"
                type="text"
                name="name"
                className="p-2 rounded-md mb-4 w-full border bg-gray-100 focus:border-gray-500 focus:outline-none"
                required
            />
            <ValidationError
                prefix="Name"
                field="name"
                errors={state.errors}
                className="text-red-500 text-sm"
            />

            {/* Email Field */}
            <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                Email Address
            </label>
            <input
                id="email"
                type="email"
                name="email"
                className="p-2 rounded-md mb-4 w-full border bg-gray-100 focus:border-gray-500 focus:outline-none"
                required
            />
            <ValidationError
                prefix="Email"
                field="email"
                errors={state.errors}
                className="text-red-500 text-sm"
            />

            {/* Message Field */}
            <label htmlFor="message" className="block text-sm font-medium text-gray-700">
                Message
            </label>
            <textarea
                id="message"
                name="message"
                className="p-2 rounded-md mb-4 w-full border bg-gray-100 focus:border-gray-500 focus:outline-none"
                rows="4"
                required
            />
            <ValidationError
                prefix="Message"
                field="message"
                errors={state.errors}
                className="text-red-500 text-sm"
            />
            <div className="gradient-border-container cursor-pointer p-2">
                <button type="submit" disabled={state.submitting} className="text-xl font-medium text-gray-500 hover:text-transparent bg-clip-text hover:bg-gradient-to-r from-[#EC6227] via-[#E80458] to-[#5900B3]">
                    Sign up
                </button>
            </div>

            {/* Submit Button */}
            {/* <GradientButton
                type="submit"
                disabled={state.submitting}
                className="bg-gradient-to-r from-[#EC6227] via-[#E80458] to-[#5900B3] text-white font-bold py-2 px-4 rounded-full"
            >
                {state.submitting ? 'Submitting...' : 'Submit'}
                {console.log("clicked")}
            </GradientButton> */}

            {/* Handle form submission errors */}
            {/* {state.errors.length > 0 && (
                <div className="text-red-500 mt-2">
                    {state.errors.map((error, index) => (
                        <p key={index}>{error.message}</p>
                    ))}
                </div>
            )} */}
        </form>
    );
}
