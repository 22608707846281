import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import PocketBaseContext from '../../PocketBaseContext';


const Journal = () => {
    const [title, setTitle] = useState('');
    const [entry, setEntry] = useState('');
    const [isDone, setIsDone] = useState(false);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [code, setCode] = useState('');
    const [entries, setEntries] = useState([]);
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const pb = useContext(PocketBaseContext);

    const handleTitleChange = (e) => {
        setTitle(e.target.value);
    };

    const handleEntryChange = (e) => {
        setEntry(e.target.value);
    };

    const handleIsDoneChange = (e) => {
        setIsDone(e.target.value === 'Yes');
    };

    const handleCodeChange = (e) => {
        setCode(e.target.value);
    };

    const handleCodeSubmit = (e) => {
        e.preventDefault();
        if (code === '1234') {
            setIsAuthenticated(true);
            setError('');
        } else {
            setError('Invalid code. Please try again.');
        }
    };

    const clearForm = () => {
        setTitle('');
        setEntry('');
        setIsDone(false);
    };

    const fetchEntries = async () => {
        try {
            const entries = await pb.collection('Journal').getFullList();
            setEntries(entries);
        } catch (error) {
            setError('Failed to fetch entries. Please try again.');
        }
    };

    useEffect(() => {
        // Fetch entries initially
        fetchEntries();

        // Subscribe to real-time changes in the Journal collection
        const unsubscribe = pb.collection('Journal').subscribe('*', function (e) {
            console.log('Database updated:', e); // You can log this to see what the event returns
            fetchEntries(); // Refetch entries when the database is updated
        });

        // Unsubscribe when the component unmounts
        return () => {
            unsubscribe();
        };
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await pb.collection('Journal').create({
                Title: title,
                Entry: entry,
                Is_Done: isDone ? 'Yes' : 'No',
            });
            clearForm();
        } catch (error) {
            console.error('Error saving entry:', error.data);
            setError('Failed to save entry. Please try again.');
        }
    };

    if (!isAuthenticated) {
        return (
            <div className="flex flex-col items-center justify-center h-screen">
                <h1 className="text-3xl font-bold mb-4">Enter Special Code</h1>
                <form onSubmit={handleCodeSubmit} className="flex flex-col items-center">
                    <input
                        type="text"
                        value={code}
                        onChange={handleCodeChange}
                        className="border p-2 mb-4"
                        placeholder="Enter code"
                    />
                    <button type="submit" className="bg-blue-500 text-white p-2 rounded">
                        Submit
                    </button>
                </form>
                {error && <p className="text-red-500 mt-2">{error}</p>}
            </div>
        );
    }

    return (
        <div className="journal-page p-4">
            <h1 className="text-3xl font-bold mb-4">Journal</h1>
            <form onSubmit={handleSubmit} className="flex flex-col">
                <input
                    type="text"
                    value={title}
                    onChange={handleTitleChange}
                    className="border p-2 mb-4"
                    placeholder="Title"
                />
                <textarea
                    value={entry}
                    onChange={handleEntryChange}
                    className="w-full h-48 p-2 border rounded mb-4"
                    placeholder="Write your journal entry here..."
                ></textarea>
                <label className="flex items-center mb-4">
                    <select
                        value={isDone ? 'Yes' : 'No'}
                        onChange={handleIsDoneChange}
                        className="mr-2 border p-2 rounded"
                    >
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                    </select>
                    Mark as Done
                </label>
                <button type="submit" className="bg-blue-500 text-white p-2 rounded">
                    Save and Exit
                </button>
            </form>
            {error && <p className="text-red-500 mt-2">{error}</p>}
            <div className="journal-entries mt-8">
                <h2 className="text-2xl font-bold mb-4">Entries</h2>
                {entries.map((entry) => (
                    <div key={entry.id} className="entry-row border-b py-2">
                        <p className="text-lg font-semibold">{entry.Title}</p>
                        <p className="text-sm text-gray-600">{new Date(entry.created).toLocaleDateString()}</p>
                        <p className="text-base">{entry.Entry}</p>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Journal;
